<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">


      <!-- Left Text-->
      <b-col
        lg="2"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
            class="sosty-terms-and-conditions-image"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="10"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            <h1><strong>TÉRMINOS Y CONDICIONES DE USO DE SITIO SOSTY S.A.S.</strong></h1>
          </b-card-title>
          <br>

          <b-card-text class="mb-2">
            <h5>
              A continuación, presentamos los términos y condiciones de uso de sitio, aplicables a los usuarios de los Servicios de la compañía SOSTY S.A.S. (en adelante, “SOSTY”), que consisten en conectar a Productores con Inversionistas para criar y posteriormente, comercializar ganado sostenible en Colombia, a través de una plataforma digital. LA plataforma es operada por SOSTY S.A.S., sociedad por acciones simplificada, identificada con Número de Identificación Tributaria NIT No. 901.466.394-5
              <br>
              <br>Los términos y condiciones describirán las reglas de uso que tendrán los usuarios de la plataforma sin hacer mención a la relación contractual que surge entre los distintos actores de la plataforma, ya que los detalles de dicha relación contractual serán objeto de regulación en documentos adicionales que se encontrarán a lo largo de la navegación por el sitio web. En ellos explicaremos los derechos y obligaciones que adquieren los Usuarios mediante el uso de la Plataforma “SOSTY” (en adelante “la Plataforma”) y las reglas bajo las cuales se mantendrá la relación. 
              <br>
              <br>Mediante la inscripción como Usuario, al adquirir alguno de nuestros servicios, y/o la aceptación electrónica expresa de los presentes términos y condiciones, el Usuario manifiesta su consentimiento y aceptación sobre los mismos. Todo lo anterior, al realizar clic y/o doble clic en los enlaces que la Plataforma tiene dispuestos para los efectos. 
              <br>
              <br>Así mismo, al efectuar alguna de las actividades mencionadas anteriormente, los Usuarios declaran que tienen capacidad jurídica y las facultades necesarias para obligarse de acuerdo con los presentes términos y condiciones.
              <br>
              <br>Los presentes términos y condiciones no configuran un contrato de sociedad, de franquicia, o una relación laboral entre SOSTY y el Usuario. 
              <br>
              <br>Los Usuarios deben leer detenidamente estos términos y condiciones, nuestra política de privacidad y tratamiento de datos personales y cualquier otro documento referenciado en estos términos y condiciones
            </h5>
            <br>

            <ol style="font-size:20px;color:black">
              <li><h2><strong>¿Quién es SOSTY S.A.S.?</strong></h2></li>
              <br>
              <h5>SOSTY S.A.S. es una sociedad comercial constituida conforme a las leyes de la República de Colombiana, identificada con NIT. No. 901.466.394-5, domiciliada en la ciudad de Bogotá, República de Colombia. La sociedad, tiene por objeto social, entre otras actividades, el desarrollo  de  sistemas informáticos de cualquier tipo de tecnología, incluyendo,  pero  sin  limitarse  a  planificación,  análisis, diseño, programación,  pruebas de desarrollo y edición de sistemas operativos, software,  hardware, códigos, bases de datos, algoritmos, páginas web, aplicaciones  comerciales  o  cualquier otro programa informático para la  puesta  en  marcha, entre otros, de servicios web para análisis y viabilidad  de  proyectos  agropecuarios,  así  como la gestión de sus recursos; actividades agropecuarias para mejorar la reproducción, el  crecimiento  y  rendimientos  de  cultivos o animales, incluyendo, pero  sin  limitarse,  a  ganado  vacuno  u  bufalino  para  su  cría, reproducción  y  engorde  en  terrenos especializado para tales fines; y planeación,  análisis, verificación, consultoría y acompañamiento en  procesos  productivos  de  agricultura  o  ganadería  con  enfoque sostenible,  incluyendo,  pero sin limitarse, a la comercialización de ganado  bovino y bufalino, así como su cría, reproducción y engorde en terrenos  aptos para tal propósito.</h5>
              <br>
              <li><h2><strong>Definiciones</strong></h2></li>
              <br>
              <h5>
                Las siguientes palabras tendrán el significado que se indica a continuación:
              </h5>
              <br>
                <ol style="list-style: none;">
                  <li>
                    <h5><strong>2.1. Plataforma:</strong>
                      es el sitio web, aplicación o desarrollo informático, independientemente de su denominación, que ofrecerá a los Usuarios el acceso a los Servicios. El nombre de la Plataforma, su formato y funcionamiento, podrá variar según lo estime conveniente SOSTY.
                    </h5>
                    <br>
                  </li>                  
                  <li>
                    <h5><strong>2.2. Proyecto Productivo: </strong>
                      se refiere a las alianzas comerciales entre los Productores e Inversionistas, quienes hacen una serie de aportes para llevar a cabo un proyecto conjunto caracterizado por el alea, con miras a que las utilidades o pérdidas sean asumidas tambien en conjunto según como se pacte en el contrato particular que suscribirán.
                    </h5>
                    <br>
                  </li>
                  <li>
                    <h5><strong>2.3. Servicios:</strong>
                      son los servicios prestados por SOSTY a través de la Plataforma, según se describe en la cláusula 4 de estos términos y condiciones. 
                    </h5>
                    <br>                    
                  </li>
                  <li>
                    <h5><strong>2.4. Usuario(s): </strong>
                      son las personas visitantes de la Plataforma, aunque estos no se hubieran registrado y que quieran utilizar la Plataforma para acceder a la información dispuesta en la misma. Dentro de los Usuarios, se identifican los siguientes: 
                    </h5>
                    <br>
                    <ol style="list-style: none;">
                      <li>
                        <h5><strong>2.4.1. Neoganaderos</strong>
                          son las personas naturales o jurídicas que están interesadas en adquirir ganado para aportarlo posteriormente a algún Proyecto Productivo.                        
                        </h5>
                        <br>                        
                      </li>
                      <li>
                        <h5><strong>2.4.2. Productores</strong>
                          personas naturales que cuentan con un terreno propio o arrendado, con conocimiento sobre el mercado de la ganadería, know-how para la cría y engorde y finalmente, que trabajan con prácticas de ganadería sostenible, los cuales requieren de un tercero que les aporte bienes muebles, como lo son el ganado, para el desarrollo de un Proyecto Productivo.                         
                        </h5>
                        <br>
                      </li>
                      <li>
                        <h5><strong>2.4.3. Visitantes</strong>
                          son aquellos sujetos que simplemente visitan la Plataforma con el propósito de conocer los Servicios u obtener información que se ofrezca en la Plataforma.
                        </h5>
                        <br>                        
                      </li>
                    </ol>
                  </li>                  
                </ol>
              <li><h2><strong>Objeto</strong></h2>
                <br>
                <h5>
                  Al registrarse en nuestra Plataforma como Usuario, al aceptar los presentes términos y condiciones y/o al adquirir alguno de nuestros Servicios, el Usuario está celebrando libre y voluntariamente un contrato de uso de sitio con SOSTY (en adelante el “Contrato”). Este Contrato incluye estos términos y condiciones y cualquier otro documento vinculante que esté publicado en nuestra Plataforma, o se le dé a conocer al Usuario por cualquier otro medio, los cuales estarán disponibles para consulta, incluyendo, pero sin limitarse a la política de privacidad y tratamiento de datos personales.
                  <br>
                  <br>Se presume que no existe ninguna relación de consumo entre los Usuarios y SOSTY toda vez que se entiende que los Usuarios de la Plataforma adquieren los Servicios para la satisfacción de una necesidad empresarial que está ligada intrínsecamente a su actividad económica. 
                </h5>
              </li>
              <br>

              <li><h2><strong>Descripción del Servicio</strong></h2>
                <br>
                <h5>
                  La Plataforma presta servicios para: i) el análisis de factibilidad de Proyectos Productivos, ii) consultoría para que dichos Proyectos sean sostenibles ambientalmente, iii) la compraventa de ganado, iv) así como de intermediación entre Productores e Inversionistas con el fin de que posteriormente se configure un Proyecto Productivo entre estos. SOSTY permite adquirir ganado que será aportado posteriormente a un Proyecto Productivo, así como para visualizar aquellos Proyectos Productivos que se pueden adelantar entre Productores e Inversionistas, que han pasado por un estudio de factibilidad, y de los cuales pueden conocerse sus características para tomar la decisión de configurar los aportes y adelantar el negocio. Cuando la campaña de cada Proyecto Productivo logre alcanzar los aportes esperados, pasará a la etapa de ejecución y el Inversionista podrá hacer seguimiento y control hasta llegar a la etapa de cierre del Proyecto Productivo, donde recibirá los retornos logrados en el Proyecto Productivo de acuerdo a su participación y las condiciones de los mismos. Finalmente, también podrá disponer de una interfaz que facilite la comercialización del ganado engordado a consecuencia del Proyecto Productivo ya ejecutado a favor de terceros o para que sea adquirido por el mismo SOSTY S.A.S.
                  <br>
                  <br>Para lo anterior, se surten los siguientes pasos:
                  <br>
                  <br> 
                  <ol style="list-style: none;">
                    <li>4.1 El Productor presenta su Proyecto Productivo a SOSTY a través de la Plataforma.</li>
                    <br>
                    <li>4.2 SOSTY realiza un análisis previo de Proyecto Productivo y verifica la información enviada por el Productor. </li>
                    <br>
                    <li>4.3 SOSTY publica el Proyecto Productivo en su vitrina virtual. </li>
                    <br>
                    <li>4.4. Los Inversionistas escogen el Proyecto Productivo que se adapta a sus necesidades y expectativas.</li>
                    <br>
                    <li>4.5 Los Inversionistas adquieren ganado en la Plataforma y posteriormente, lo aportan al Proyecto Productivo. </li>
                    <br>
                    <li>4.6 El ganado es trasladado físicamente al predio del Productor para que empiece su objetivo por el cual fue adquirido.  </li>
                    <br>
                    <li>4.7 Se crea un acta de constitución del Proyecto Productivo. </li>
                    <br>
                    <li>4.8 El Productor e Inversionista puedan estar al tanto del estado del Proyecto Productivo en la Plataforma. </li>
                    <br>
                    <li>4.9 Cuando el ganado alcance su meta de peso, y se venda, se liquida, y se reparten las utilidades del Proyecto Productivo de acuerdo a la participación de los Inversionistas y el Productor, descontando los costos y gastos del Proyecto Productivo según las condiciones del contrato de colaboración del Proyecto. </li>
                  </ol>
                  <br>
                  <h5>
                    SOSTY prestará servicios de seguimiento y control sobre los Proyectos Productivos, los cuales podrán incluir visitas al predio del Productor. 
                    <br>
                    Para más información visite el presente link:  https://www.sosty.co/
                  </h5>
                </h5>
              </li>
              <br>

              <li><h2><strong>Responsabilidades de los Productores </strong></h2>
              <br>
                <h5>Los Productores garantizarán y serán responsables, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los datos que suministren, así como de la información entregada sobre los Proyectos Productivos y los aportes que ofrezcan a los mismos. En todo caso, la Plataforma podrá requerir en cualquier momento, la información que considere necesaria para verificar la información suministrada.
                  <br>
                  <br>Los Productores mantendrán indemne y responderán por cualquier perjuicio, daño o inconveniente ocasionado a SOSTY o a un tercero, generado por el suministro de información falsa, errónea e imprecisa. 
                  <br>
                  <br>Si la información de los Proyectos Productivos presenta algún cambio frente a la publicación realizada en la Plataforma, los Productores deberán actualizarla a través de los medios dispuestos para ello.
                  <br>
                  <br>Los Productores aceptan que las publicaciones podrán ser deshabilitadas si la Plataforma determina que se ha suministrado información incorrecta, falsa o imprecisa.
                </h5>
              </li>
              <br>

              <li><h2><strong>Requisitos para contratar</strong></h2>
              <br>
                <h5>
                  Para que los Usuarios puedan dar su consentimiento y celebrar el presente Contrato, deben cumplir como mínimo con los siguientes requisitos:
                </h5>
                <br>
                <h5>
                <ol type="a">
                  <li>Tener la capacidad para celebrar un contrato vinculante.</li>
                  <br>
                  <li>Contar con recursos (predios, know how, etc) propios de su actividad ganadera para aportar al Proyecto, en el caso de los Productores, o contar con recursos líquidos que provengan de actividades lícitas para adquirir ganado, que luego aportarán a los Proyectos Productivos, en el caso de los Inversionistas. </li>
                  <br>
                  <li>Tener domicilio en Colombia.</li>
                  <br>
                  <li>Cualquier otro requisito que SOSTY estime conveniente. </li>
                  <br>
                </ol>
                </h5>
                <h5>En caso de que quien adquiera los Servicios lo haga a nombre de una persona jurídica o de un tercero, dicha persona garantiza que está plenamente facultada para obligar y/o representar a dicha persona. En caso de no estar facultado, el adquiriente responderá personalmente por las obligaciones contraídas de acuerdo con los presentes términos y condiciones.</h5>
              </li>
              <br>

              <li><h2><strong>Sobre el proceso de inscripción de los Usuarios y reglas relativas a la cuenta</strong></h2></li>
              <br>
                <ol style="list-style: none;">
                  <h5>
                  <li><h4><strong>7.1. Términos y condiciones generales del registro </strong></h4>                  
                  <br>
                    <ol style="list-style: none;">
                      <li>7.1.1.	Es obligación de los Usuarios diligenciar el formulario de registro en todos sus campos, con datos válidos, para poder utilizar los servicios que presta la Plataforma. La Plataforma podrá requerir en cualquier momento, la información que considere necesaria para verificar la información suministrada.</li>
                      <br>
                      <li>7.1.2.	Todo Usuario deberá completar la información requerida de manera exacta, precisa y verdadera, y asume el compromiso de actualizar sus datos siempre que sea necesario. Para dichos efectos, y previo al recaudo de los respectivos datos personales, los Usuarios deberán leer y conocer la Política de Protección de Datos Personales de la Plataforma y autorizar el tratamiento de sus datos de conformidad con dicha política, la cual se encuentra en disponible en la siguiente URL: https://www.sosty.co/legal. </li>
                      <br>                                    
                      <li>7.1.3.	Los Usuarios garantizarán y serán responsables, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los datos personales que suministren. </li>
                      <br>
                      <li>7.1.4.	Los Usuarios responderán por cualquier perjuicio, daño o inconveniente generado por el suministro de información falsa, errónea e imprecisa.</li>
                      <br>
                      <li>7.1.5.	Si la información personal de los Usuarios presenta algún cambio que llegare a afectar alguno de los Servicios ofrecidos por la Plataforma, los Usuarios deberán actualizarla a través de los medios dispuestos para ello.</li>
                      <br>
                      <li>7.1.6.	El Usuario acepta y reconoce que SOSTY utilizará como método principal de comunicación la dirección de correo electrónico registrada en la cuenta.</li>
                      <br>
                      <li>7.1.7.	Los Usuarios aceptan que las cuentas o perfiles creados podrán ser deshabilitados o limitados si la Plataforma determina que se ha suministrado información incorrecta, falsa o imprecisa por medio de la Plataforma. </li>
                      <br>   
                      <li>7.1.8.	La cuenta es personal, única e intransferible. Un mismo Usuario no podrá inscribir o poseer más de una cuenta habilitada.</li>
                      <br>
                      <li>7.1.9.	El Usuario accederá a su cuenta mediante el ingreso del correo electrónico registrado en la Plataforma y clave de seguridad personal elegida. El Usuario se obliga a mantener la confidencialidad de su clave de seguridad y a tomar las medidas de seguridad necesarias para que no ocurra ningún incidente de seguridad respecto del manejo de su cuenta y la respectiva clave de seguridad que le asiste. SOSTY no se hará responsable por ninguna pérdida o daño que surja como resultado de la inseguridad de su cuenta.</li>
                      <br>
                      <li>7.1.10.	SOSTY podrá rechazar su solicitud de cuenta o cancelar una cuenta existente por cualquier motivo, a entera discreción de SOSTY.</li>
                      <br>
                      <li>7.1.11.	Las cuentas de los Usuarios no podrán ser cedidas o transferidas, ni tampoco podrán estar sujetas a cualquier otra operación comercial que no sea reconocida expresamente por los presentes términos y condiciones. El Usuario se hace responsable por cualquier uso no autorizado por un tercero, sea hecho voluntariamente o involuntariamente, o de cualquier tercero que pueda acceder a sus credenciales de acceso por hechos realizados por los Usuarios catalogados hasta por culpa levísima. El Usuario se compromete a notificar a SOSTY en forma inmediata, por un medio idóneo y fehaciente, de cualquier uso no autorizado de su cuenta, así como el ingreso por terceros no autorizados a la misma.</li>
                      <br>
                      <li>7.1.12.	El Usuario es el único responsable de la actividad y contenido administrado desde su cuenta, incluyendo, pero sin limitarse a fotos, imágenes, videos, gráficos, textos, archivos de audio, códigos, contraseñas, información o datos cargados, recopilados, generados, almacenados, mostrados, distribuidos, transmitidos o exhibidos en o en conexión con la cuenta, así como las transacciones de compra realizadas. </li>
                    </ol>
                  </li>
                  </h5>
                  <br>

                  <li><h4><strong>7.2. Causales de rechazo, suspensión y/o inhabilitación de inscripción de cuentas</strong></h4>                  
                  <br>
                  <h5>
                    SOSTY podrá rechazar, cancelar, suspender o inhabilitar cualquier cuenta –sea que esté en proceso de aceptación o ya haya sido aceptada–, cuando ocurra alguno de los siguientes eventos:
                  </h5>
                  <br>
                  <h5>
                    <ol style="list-style: none;">
                      <li>7.2.1.	Será rechazada toda solicitud de cuenta, donde se encuentre información falsa, incorrecta, equivocada, imprecisa, errónea o confusa. En caso de rechazo, se deberá enviar un correo a hola@sosty.co con la copia del documento que permita constatar la identidad para realizar el proceso de verificación correspondiente.</li>
                      <br>
                      <li>7.2.2.	Serán canceladas todas las cuentas adicionales a la cuenta original que cree un Usuario.</li>
                      <br>                                    
                      <li>7.2.3.	Serán suspendidas las cuentas de un Usuario cuando un Usuario incumpla alguna de sus obligaciones respecto de la Plataforma. En caso de incumplir por más de tres (3) ocasiones, su cuenta será cancelada. Sin perjuicio de lo anterior, podrá proceder la suspensión temporal de una cuenta, en tanto se constate la existencia de un indicio que dé cuenta de un potencial incumplimiento o vulneración a las disposiciones legales o a los presentes términos y condiciones. </li>
                      <br>
                      <li>7.2.4.	Cuando se realice una operación ilegal o que atente contra la seguridad de bienes o personas, a través de una cuenta registrada, tal cuenta será cancelada, y de ser el caso, se avisará a las autoridades competentes de la irregularidad presentada.</li>
                      <br>
                      <li>7.2.5.	Será cancelada, inhabilitada o suspendida temporalmente, una cuenta, a petición del Usuario, siempre y cuando, dicha cuenta no tenga obligaciones o compromisos pendientes por cumplir, bien con otro Usuario, bien con la Plataforma.</li>
                      <br>
                      <li>7.2.6.	Será suspendida temporalmente, toda cuenta que utilice un lenguaje inapropiado en el desarrollo de sus operaciones e interacciones. Por lenguaje inapropiado, se entenderá aquel que sea irrespetuoso, afecte la honra y buen nombre de las personas, o sea sexual, soez, o vulgar.</li>
                      <br>
                      <li>7.2.7.	Cuando SOSTY advierta que un Usuario no está siendo representado por quien cuenta con las facultades legales y/o convencionales para tales efectos.</li>
                      <br>
                    </ol>
                  </h5>
                  </li>
                  <br>
                  
                  <li><h4><strong>7.3.	Cancelación voluntaria de cuentas</strong></h4>                  
                  <br>
                  <h5>
                    <ol style="list-style: none;">
                      <li>7.3.1.	Para cancelar voluntariamente una cuenta, el Usuario deberá ingresar a la Plataforma, y enviar a través del correo registrado una solicitud a hola@sosty.co con el asunto “CERRAR CUENTA”. Una vez enviada la comunicación, se iniciará el proceso para verificar si la cuenta cumple con los requisitos expuestos en estos Términos y Condiciones para poder realizarse la misma. </li>
                      <br>
                      <li>7.3.2.	No se podrá cancelar voluntariamente aquellas cuentas en las que aparezcan obligaciones pendientes.</li>
                      <br>                                                          
                    </ol>
                  </h5>
                  </li>
                  <br>
                </ol>

              <li><h2><strong>Obligaciones de los Usuarios</strong></h2></li>
                <br>
                <h5>
                  Los Usuarios están obligados a cumplir con todas las obligaciones que se expresan en los presentes términos y condiciones, al igual que con aquellas que se determinen en otros documentos, incluyendo, pero sin limitarse a, otros contratos que sean celebrados con SOSTY y las Políticas de Protección de Datos Personales. De igual forma, con la aceptación de los presentes términos y condiciones. Además de estas, los Usuarios tendrán las siguientes obligaciones:
                </h5>
                <br>
                  <ol style="list-style: none;">
                    <h5>
                    <li><h4><strong>8.1.	Obligaciones generales de los Usuarios</strong></h4>                  
                    <br>
                      <ol style="list-style: none;">
                        <li>8.1.1.	Dirigirse con respeto a los otros Usuarios y al personal de servicio al cliente de la Plataforma.</li>
                        <br>
                          <ol style="list-style: none;">
                            <li>8.1.1.1.	Proporcionar de manera completa y fidedigna la información que le sea solicitada para la efectiva publicación del Proyecto Productivo en la Plataforma.</li>
                          </ol>
                          <br>
                        <li>8.1.2.	Dar aviso a la Plataforma sobre cualquier irregularidad o ilegalidad de la que tenga conocimiento, y que haya ocurrido o esté ocurriendo en la Plataforma.</li>
                        <br>                                    
                        <li>8.1.3.	Abstenerse de realizar publicaciones y/o actos que infrinjan los derechos de propiedad intelectual de terceros.</li>
                        <br>
                        <li>8.1.4.	Leer y conocer a cabalidad los presentes términos y condiciones y la Política de Tratamiento de Datos Personales de SOSTY.</li>
                        <br>
                        <li>8.1.5.	Suministrar información exacta, completa y veraz en las publicaciones de los Proyectos Productivos.</li>
                        <br>
                        <li>8.1.6.	Abstenerse de subir o distribuir cualesquiera archivos que contengan virus, archivos corruptos o cualquier otro software similar o programas que puedan perjudicar o dañar la operatividad de la Plataforma y/o sistemas operativos de los operadores y/u otros Usuarios.</li>
                        <br>
                        <li>8.1.7.	Abstenerse de modificar, desensamblar, descompilar o aplicar ingeniería inversa a la Plataforma.</li>
                        <br>   
                        <li>8.1.8.	Abstenerse de probar, testear, escanear la vulnerabilidad, o tratar de evadir los mecanismos de seguridad implementados en los sitios web, servidores o redes conectadas a la Plataforma, o relacionadas con la misma.</li>
                        <br>
                        <li>8.1.9.	Abstenerse de acceder o usar la información de cualesquiera otros Usuarios o terceras personas, a través o al hacer uso de la Plataforma, sin estar autorizado debidamente para el efecto.</li>
                        <br>
                        <li>8.1.10.	Abstenerse de reducir o impedir de manera deliberada la accesibilidad, usabilidad u operatividad de la Plataforma.</li>
                        <br>
                        <li>8.1.11.	Abstenerse de utilizar la Plataforma para publicar, promover o trasmitir material o contenido ilegal, abusivo, difamador, amenazador, dañino o discriminatorio.</li>
                        <br>
                        <li>8.1.12.	Pagar por los Servicios adquiridos.</li>
                      </ol>
                    </li>
                    </h5>
                    <br>
                  </ol>

                <li><h2><strong>Tarifas </strong></h2>
                  <br>
                  <h5>
                    Las tarifas de los Servicios ofrecidos en la Plataforma serán determinadas en tarifarios publicados a través de la Plataforma. Dichas tarifas, podrán ser actualizadas regularmente, dependiendo de las condiciones del mercado y a discreción de SOSTY, siendo previamente informado cualquier cambio a los Usuarios.
                    <br>
                    <br>
                    Cuando por errores de carácter tecnológico se exhiban precios erróneos de los Servicios en la Plataforma o en cualquier otro medio de exhibición, que evidentemente resultan desproporcionados o irrisiorios, comparativamente con el precio de los Servicios en el mercado, SOSTY podrá cancelar la(s) Suscripciones o suspender los Servicios adquiridos con dichos precios, a su libre discreción y con plena autonomía, sin que lo anterior implique incumplimiento o cualquier tipo de multa, sanción o indemnización a favor del Usuario, y le informará oportunamente la situación al Usuario. 
                  </h5>
                  <br>
                </li>

                
                <li><h2><strong>Cobertura del servicio </strong></h2>
                  <br>
                  <h5>
                    El área de cobertura de los Servicios es la del territorio correspondiente a la República de Colombia. No obstante, tal cobertura podrá variar, caso en el cual será oportunamente informado a los Usuarios.
                    <br>                    
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Disponibilidad de los Servicios y Proyectos Productivos</strong></h2>
                  <br>
                  <h5>
                    Los Servicios y Proyectos Productivos ofrecidos en la Plataforma, son el reflejo de los Servicios que SOSTY está en capacidad de ofrecer y de los Proyectos Productivos que están disponibles para recibir aportes. Sin embargo, si se llegare a presentar el caso en el que el Usuario adquiera un Servicio que no está disponible o seleccione algún Proyecto Productivo que no está disponible, SOSTY se comunicará con el Usuario para ofrecer las alternativas correspondientes según la disponibilidad de los Servicios y Proyectos Productivos.
                    <br>                    
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Facturación, Cobro, Impuestos y Pago</strong></h2></li>
                <br>
                  <ol style="list-style: none;">
                    <h5>
                    <li><h5><strong>12.1. Facturación: </strong>SOSTY emitirá la factura de venta correspondiente al Servicio adquirido por el Usuario. La periodicidad de la facturación podrá variar dependiendo de la periodicidad del Servicio o suscripción que adquiera el Usuario. </h5></li> 
                    <br>                      
                    <li><h5><strong>112.2. Cobro: </strong>El cobro de los Servicios o suscripción que adquiera el Usuario se realizará a través de los medios de pago dispuestos en la Plataforma o por cualquier otro medio que SOSTY estime conveniente. </h5></li> 
                    <br>                      
                    <li><h5><strong>12.3.	Impuestos: </strong>Tanto SOSTY como los Usuarios serán responsables de la declaración, retención y pago de todos los impuestos aplicables conforme a las leyes, reglamentos o cualquier ordenamiento aplicable vigente.</h5></li> 
                    <br> 
                    </h5>
                    <br>
                  </ol>

                <li><h2><strong>Peticiones, Quejas, Reclamos y Sugerencias (PQRS)</strong></h2>
                  <br>
                  <h5>Las peticiones, quejas, reclamos y sugerencias respecto de los servicios de la Plataforma se regirán por las siguientes disposiciones:</h5>
                  <br>
                </li>
                  <ol style="list-style: none;">
                    <h5>
                    <li>13.1.	Las PQRS podrán ser presentada en forma escrita, o de manera electrónica enviando un correo electrónico a hola@sosty.co o en la siguiente URL: https://www.sosty.co/contacto </li> 
                    <br>                      
                    <li>13.2.	SOSTY registrará e individualizará la reclamación asignándole un número a la reclamación, que será puesto en conocimiento de quien presentó la misma, a través de una copia o informándole el número de ésta. SOSTY deberá emitir la respuesta dentro de los siguientes quince (15) días hábiles a la fecha de recepción de la petición o reclamo, y no estará obligado a dar respuesta a las sugerencias. </li> 
                    <br> 
                    </h5>
                  </ol>

                <li><h2><strong>Estándares de Calidad y Garantía de los Servicios</strong></h2>
                  <br>
                  <h5>La Plataforma hará sus mejores esfuerzos para prestar los Servicios con la calidad que esperan los Usuarios, sujeto a los presente términos y condiciones.
                  <br>
                  <br>No obstante lo anterior, los Servicios no tienen ninguna garantía y se prestan conforme a los términos de la cláusula 15 de estos términos y condiciones.
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Servicio “AS IS”</strong></h2>
                  <br>
                  <h5>
                    Los Usuarios declaran y garantizan que los Servicios se ofrecen “As Is”, es decir que no tienen ninguna garantía legal, en el entendido de que no se enajenan en una relación de consumo sino en el marco de una relación comercial. 
                    <br>
                    <br>Ningún tipo de información que el Usuario haya recibido por algún medio por parte de SOSTY o cualquiera de sus representantes o funcionarios, adicional a estos términos y condiciones, comprometerá la responsabilidad de SOSTY ni representará garantía alguna sobre los Servicios.
                    <br>
                    <br>No obstante lo anterior, SOSTY hará sus mejores esfuerzos para satisfacer las expectativas de los Usuarios y en caso de presentarse una disconformidad con los Servicios, el Usuario deberá manifestarla por escrito a SOSTY, con el fin de que SOSTY presente las alternativas de corrección correspondientes dentro de loa 15 días hábiles posteriores a la recepción de la disconformidad. 
                    <br>
                    <br>En caso de no alcanzar un grado de satisfacción razonable después de las alternativas presentadas por SOSTY, el único recurso que el Usuario tendrá en relación con los Servicio que presta la Plataforma, será el de cancelar su Cuenta y no volver a usar la Plataforma.
                    <br>                  
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Derecho de Retracto </strong></h2>
                  <br>
                  <h5>No aplicará el derecho de retracto a favor de los Usuarios debido a que este Contrato se celebra en el marco de una relación comercial y no de una relación de consumo.
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Hipervínculos a otros sitios web y cookies</strong></h2>
                  <br>
                  <h5>El Usuario acepta que SOSTY puede hacer uso de cookies y otras tecnologías similares, tanto en la Plataforma, sus contenidos, servicios, como en los correos electrónicos enviados a los Usuarios, con la finalidad de, entre otras, propósitos de autenticación, registrar las actividades en la Plataforma, mejorar la funcionalidad de la misma, optimizar los Servicios ofrecidos, analizar tendencias del mercado, analizar la información demográfica de quienes visitan la Plataforma y hacen uso de los servicios que el mismo ofrece, evaluar la eficacia de la publicidad del mismo, comportamiento de los Usuarios de la Plataforma y resultado de las actividades allí ejecutadas, determinar quién ha abierto el correo electrónico enviado y el formato en que lo hace. Estas herramientas permiten la obtención de, entre otras, información referente al tipo de navegador y el sistema operativo que se utiliza, la dirección IP, el tiempo que se permanece en la Plataforma y el número de visitas realizadas a la Plataforma, el uso de la misma, los Servicios visitados por el Usuario, entre otros. 
                  <br>
                  <br>El Usuario puede configurar su navegador para deshabilitar y eliminar las cookies, en cuyo caso, a pesar de que se podrá continuar visitando la Plataforma, el acceso a determinadas características de la Plataforma pueden restringirse.
                  </h5>
                  <br>
                </li>
                
                <li><h2><strong>Tratamiento de Datos Personales</strong></h2>
                  <br>
                  <h5>Con la aceptación de los presentes términos y condiciones, SOSTY queda autorizado para la recopilación, uso y tratamiento de los datos personales contenidos en la Plataforma y aquellos que se llegaren a suministrar o recopilar en el futuro, para las finalidades y en los términos descritos en la Política de Tratamiento de Datos Personales de SOSTY, la cual se encuentra disponible en https://www.sosty.co/legal.
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Acuerdos de niveles de servicio.</strong></h2>
                  <br>
                  <h5>SOSTY se compromete a mantener una disponibilidad de la Plataforma con su proveedor de servidor en la nube, el cual se interrumpirá por mantenimiento de la Plataforma o por otras razones que SOSTY estime convenientes, en forma continua o acumulada. En todo caso, se dará aviso en un término razonable sobre cualquier interrupción en la prestación del servicio, si esto fuere posible. 
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Propiedad Intelectual</strong></h2>
                  <br>
                  <h5>La Plataforma, las soluciones y los diseños, gráficos, textos, imágenes, código fuente y código objeto, modelo de uso y demás contenido al que el Usuario tiene acceso para la adquisición de los Servicios, es de propiedad de SOSTY y está protegido por la propiedad intelectual e industrial según corresponda.
                  <br>
                  <br>Los signos distintivos, tales como marcas, nombres comerciales, logotipos, y todos los componentes de los signos distintivos de SOSTY, son propiedad exclusiva de la misma, por lo cual los Usuarios no tienen el derecho a usar las marcas sin autorización.
                  <br>
                  <br>Autorización de uso de marca y derechos de imagen. Los Usuarios autorizan a la Plataforma para que utilice su marca o derechos de imagen, únicamente para efectos de publicarla en su página web o en cualquier otro documento que emita Sosty para fines de mercadeo, incluyendo pero sin limitarse a, ofertas comerciales, presentaciones empresariales, publicidad, entre otras. El uso de la marca o los derechos de imagen, no implican el otorgamiento, o de manera alguna confieren derechos de propiedad sobre la marca o los derechos de imagen.
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Fallas en la Plataforma</strong></h2>
                  <br>
                  <h5>SOSTY no se responsabiliza por cualquier daño, perjuicio o pérdida causados por fallas en la Plataforma derivadas del servidor o Internet. SOSTY tampoco será responsable por cualquier virus que pudiera infectar su equipo como consecuencia del acceso o uso de la Plataforma o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audios contenidos en el mismo. El Usuario no podrá imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas de Internet. SOSTY no garantiza el acceso y uso continuado o ininterrumpido de su Plataforma por encima de lo indicado en los Acuerdos de Niveles de Servicio. 
                  <br>
                  <br>La Plataforma puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a SOSTY; en tales casos se procurará restablecerla con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad a SOSTY. SOSTY no será responsable por ningún error u omisión que el Usuario cometa dentro de la Plataforma.                                     
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Responsabilidad de SOSTY</strong></h2>
                  <br>
                  <h5>Los Usuarios aceptan y reconocen que la Plataforma se alimenta de la información que los Usuarios ingresen a ella, de modo que SOSTY no se hace responsable de ningún tipo de daño o perjuicio que aleguen los Usuarios en relación con a los Servicios y en caso de comprobarse en juicio algún tipo de responsabilidad de SOSTY, esta estará limitada al 100% del valor de los Servicios o suscripciones efectivamente pagadas por el Usuario que alega la responsabilidad. 
                  <br>
                  <br>La Plataforma informa a los Usuarios que quedará exenta de responsabilidad cuando:
                  <br>
                  </h5>
                  <br>                  
                </li>
                  <ol style="list-style: none;">
                    <h5>
                    <li>22.1 Los Usuarios proporcionen datos de identidad o información incorrectos, inexactos, no actualizados o falsos, y en particular, aunque de modo no exclusivo ni limitativo, por daños y perjuicios de cualquier naturaleza que se puedan deber a suplantación de personalidad de un tercero, efectuada por un Usuario en cualquier clase de comunicación o transacción realizada a través de la Plataforma. </li> 
                    <br>                      
                    <li>22.2 Por consecuencias derivadas del contenido de la información publicada por los Usuarios, sin perjuicio de que la Plataforma pueda limitar o borrar cualquier contenido a su discreción. </li> 
                    <br> 
                    <li>22.3 Cuando se produzca suspensión, interrupción o corte del servicio debido a mantenimiento correctivo o preventivo.</li>
                    <br>
                    <li>22.4 Cuando se deba realizar modificaciones urgentes que sean necesarias en la Plataforma por causas ajenas a la voluntad de la Plataforma en casos de congestionamiento de magnitud en las líneas o señales, casos de fuerza mayor o fortuitos atribuibles a la naturaleza, a las condiciones atmosféricas, a altas o bajas en el voltaje del sistema eléctrico y otros de similar naturaleza.</li>
                    <br>
                    <li>22.5 La Plataforma no ofrece garantías sobre los servicios o Proyectos Productivos publicados en la Plataforma. </li>
                    <br>
                    <li>22.6 Por el cumplimiento de los supuestos de los proyectos productivos o la ejecución de los mismos por parte del Productor.</li>
                    <br>
                    <li>22.7 Por virus importados a través de la red o cualquiera sea su origen. </li>
                    <br>
                    <li>22.8 Por el uso inadecuado de los Servicios por parte del Usuario, ya sea por desconocimiento del uso de equipos, mala configuración de estos, fallas técnicas de acceso u otras similares en los equipos de propiedad de los Usuarios.  </li>
                    <br>
                    <li>22.9 Por pérdida de ganancias por negocios no realizados por los Usuarios, lucro cesante, pérdidas pecuniarias o pérdidas de información o datos, ya sea por la imposibilidad de habilitar el servicio a tiempo o por cualquier tipo de falla en el servicio; por cualquier pérdida económica resultante de bloqueos dentro del marco legal u otra limitación de acceso debidamente justificada. </li>
                    <br>
                    <li>22.10 Por cualquier costo, perjuicio o daño que sea causado a los Usuarios, como consecuencia de la utilización indebida de los Servicios por personas ajenas a la Plataforma.</li>
                    <br>
                    <li>22.11 Por las infracciones de derechos de propiedad intelectual efectuadas por terceros, quienes se atribuyan la condición de derecho habientes, ya sea como titulares o licenciatarios de tales derechos, o quienes presuman contar con cualquier tipo de autorización para su uso.</li>                    
                    <br>                                                                                                                                                           
                    </h5>
                  </ol>

                <li><h2><strong>Modificaciones </strong></h2>
                  <br>
                  <h5>El uso de las funcionalidades incluidas en la Plataforma por parte de los Usuarios, implica la aceptación de los presentes Términos y Condiciones. Si cualquier Usuario está en desacuerdo con alguno de los elementos contenidos en los Términos y Condiciones, deberá cesar el uso de la Plataforma.
                  <br>
                  <br>Los presentes Términos y Condiciones constituyen un acuerdo legalmente vinculante entre los Usuarios y Sosty.
                  <br>
                  <br>SOSTY podrá realizar actualizaciones y cambios a los presentes Términos y Condiciones, tanto en su apariencia como en sus características y contenido, y este podrá ser cambiado o modificado en la medida en que se desarrollen nuevas funciones o características y a las condiciones de acceso en cualquier momento, sin que esto signifique compromiso adicional a los establecidos por parte de la Plataforma ni se alegue algún tipo de perjuicio.
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Ley Aplicable</strong></h2>
                  <br>
                  <h5>El Usuario acepta que los presentes términos y condiciones se regirán por la legislación colombiana. En el análisis del presente documento, los documentos del mismo se interpretarán en el siguiente orden: (i) Los términos y condiciones (ii) La política de tratamiento de datos personales (iii) Cualquier otro documento escrito suscrito por el Usuario y por SOSTY con ánimo de que haga parte de los documentos vinculantes.                  
                  </h5>
                  <br>
                </li>

                <li><h2><strong>Resolución de Conflictos </strong></h2>
                  <br>
                  <h5>El Usuario acepta que para cualquier divergencia o discrepancia que se presente durante el uso de la Plataforma, el Usuario y SOSTY dispondrán de un término de treinta (30) días calendario, contados a partir de la fecha del reclamo, para solucionar sus diferencias acudiendo a la negociación directa o a la conciliación.
                  <br>
                  <br>Si pasado este tiempo, no podemos llegar a ningún acuerdo, tanto el Usuario como SOSTY, son libres de acudir a la justicia ordinaria. 
                  <br>
                  <br>Gracias por leer con atención nuestros términos y condiciones.
                  </h5>
                  <br>
                </li>

            </ol>

            <h2 align="right">Última actualización: 05 de abril de 2021</h2>  




            
          </b-card-text>


          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Volver al login
            </b-link>
          </p>
          
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/sosty-login.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>